var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "", "grid-list-xl": "" } },
    [
      _c(
        "v-flex",
        { staticClass: "pt-1" },
        [
          _c(
            "material-card",
            {
              attrs: {
                px3: "",
                color: "primary",
                title: "AUTORIZACIONES SALUD WEB",
              },
            },
            [
              _c(
                "v-layout",
                { attrs: { "justify-center": "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "pt-1" },
                    [
                      _c(
                        "v-container",
                        [
                          _c("v-flex", [
                            _c("h3", { staticClass: "subheading" }, [
                              _vm._v("Información general"),
                            ]),
                          ]),
                          _c(
                            "v-layout",
                            { staticClass: "ml-1", attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Ips que emite" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Consecutivo" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Fecha Autorización (aaaa/mm/dd)",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label:
                                        "Fecha Ingreso Sistema (aaaa/mm/dd)",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "\tNúmero Solicitud" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Lugar Radicación" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "\tTipo Prestación" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Ciudad" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-flex", { staticClass: "pt-1" }, [
                            _c("h3", { staticClass: "subheading" }, [
                              _vm._v("Información del Afiliado"),
                            ]),
                          ]),
                          _c(
                            "v-layout",
                            { staticClass: "ml-1", attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Tipo de identificacion" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "\tNúmero de Identificación",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Nombre" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Ips del afiliado" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Marcación Epidemiológica",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-flex", { staticClass: "pt-1" }, [
                            _c("h3", { staticClass: "subheading" }, [
                              _vm._v("Datos contacto del afiliado"),
                            ]),
                          ]),
                          _c(
                            "v-layout",
                            { staticClass: "ml-1", attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md2: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Teléfono Contacto" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Correo Electrónico" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md2: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Celular" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Ips del afiliado" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md2: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label:
                                        "Autoriza Envio Correo Electrónico y Mensajes",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-flex", { staticClass: "pt-1" }, [
                            _c("h3", { staticClass: "subheading" }, [
                              _vm._v("Información del remintente"),
                            ]),
                          ]),
                          _c(
                            "v-layout",
                            { staticClass: "ml-1", attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md2: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Remitido por" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Nombre" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-flex", { staticClass: "pt-1" }, [
                            _c("h3", { staticClass: "subheading" }, [
                              _vm._v("Información de la autorización"),
                            ]),
                          ]),
                          _c(
                            "v-layout",
                            { staticClass: "ml-1", attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Origen del servicio" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Tipo de convenio" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Alto costo" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Tipo de cobro" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Valor copago honorarios" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Valor Pagado Afiliado" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Porcentaje Carencias" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Tipo Recaudo" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Estado Autorización" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Reemplaza a" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Por Urgencias" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Tipo de Evento" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Fecha Activación" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Lugar Generación" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Ips Imprime" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label:
                                        "Fecha Impresión (aaaa/mm/dd) 24hrs (hh:mm:ss)",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Tipo Recobro" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Tipo Agrupación" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Consecutivo Evento" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Porcentaje Copago" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Clasificación de Ingresos",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Valor Cobrado Institución",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Valor Carencias Honorarios",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Sucursal Recaudo" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Código Digitador" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Reemplazada por" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Usuario que origina en IPSA",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Tipo de Orden" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "\tFecha Vencimiento" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Lugar Impresión" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Usuario Imprime" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-layout", { attrs: { wrap: "" } }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-2",
                                    attrs: { small: "", color: "primary" },
                                  },
                                  [_vm._v("Imprimir")]
                                ),
                                _c(
                                  "v-btn",
                                  { attrs: { small: "", color: "primary" } },
                                  [_vm._v("Observaciones")]
                                ),
                                _c(
                                  "v-btn",
                                  { attrs: { small: "", color: "primary" } },
                                  [_vm._v("Derechos")]
                                ),
                                _c(
                                  "v-btn",
                                  { attrs: { small: "", color: "primary" } },
                                  [_vm._v("Novedades")]
                                ),
                                _c(
                                  "v-btn",
                                  { attrs: { small: "", color: "primary" } },
                                  [_vm._v("Marcaciones")]
                                ),
                                _c(
                                  "v-btn",
                                  { attrs: { small: "", color: "primary" } },
                                  [_vm._v("Enviar autorizacion OPC")]
                                ),
                                _c(
                                  "v-btn",
                                  { attrs: { small: "", color: "primary" } },
                                  [_vm._v("Cerrar")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }